import React, {Component} from 'react';
import { Container, Spinner } from 'react-bootstrap';
import '../App.css';

export default class Preloader extends Component{

    render(){
        return(
            <Container fluid>
                <div className="div-loader">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
            </Container>
        )
    }
}